import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

import { Ref } from "vue";
import { screens } from "@/configs/breakpointsConfig.json";

export const useAppBreakpoints = () => {
  const breakpoints = useBreakpoints({
    ...breakpointsTailwind,
    ...screens,
  });
  const state = reactive<Record<string, Ref<boolean>>>({
    smAndUp: ref(false),
    sm: ref(false),
    mdAndDown: ref(false),
    md: ref(false),
    mdAndUp: ref(false),
    lgAndDown: ref(false),
    lg: ref(false),
    lgAndUp: ref(false),
    lg2AndDown: ref(false),
    lg2: ref(false),
    lg2AndUp: ref(false),
    xl: ref(false),
    xlAndUp: ref(false),
    xxl: ref(false),
    xxxl: ref(false),
  });

  onMounted(() => {
    state.smAndUp = breakpoints.greaterOrEqual("sm");
    state.sm = breakpoints.smaller("sm");
    state.mdAndDown = breakpoints.smallerOrEqual("md");
    state.md = breakpoints.between("sm", "md");
    state.mdAndUp = breakpoints.greaterOrEqual("md");
    state.lgAndDown = breakpoints.smallerOrEqual("lg");
    state.lg = breakpoints.between("md", "lg");
    state.lgAndUp = breakpoints.greaterOrEqual("lg");
    state.lg2AndDown = breakpoints.smallerOrEqual("lg");
    state.lg2 = breakpoints.between("lg", "lg2");
    state.lg2AndUp = breakpoints.greaterOrEqual("lg2");
    state.xl = breakpoints.between("lg2", "xl");
    state.xlAndUp = breakpoints.greaterOrEqual("xl");
    state.xxl = breakpoints.between("xl", "2xl");
    state.xxxl = breakpoints["2xl"];
  });

  return toRefs(reactive(state));
};
